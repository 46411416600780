import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Result from '../views/Result.vue';
import StateDirectory from '../views/StateDirectory.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/result/:postalCode',
    name: 'Result',
    component: Result,
  },
  {
    path: '/state/:state',
    name: 'StateDirectory',
    component: StateDirectory,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    try {
      window.FB.XFBML.parse();
    } catch (e) {}
    try {
      twttr.widgets.load();
    } catch (e) {}

    var ads = document.getElementsByClassName('adsbygoogle').length;
    for (var i = 0; i < ads; i++) {
      try {
        (adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {}
    }
  });
});

export default router;
