export default {
  install: function(Vue, options) {
    const {
      VUE_APP_API_BASE_URI
    } = process.env;

    Vue.prototype.$api = (method = 'GET', path = '/', data = {}, headers = {}) => {
      const request = {
        method,
        mode: 'cors',
        headers
      };

      if(method === 'POST' || method === 'PATCH') {
        request.headers['Content-Type'] = 'application/json';
        request.body = JSON.stringify(data);
      }

      return fetch(`${VUE_APP_API_BASE_URI}${path}`, request)
        .then(response => response.json())
    };
  }
};