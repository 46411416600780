<template>
  <div id="sdp" class="container-fluid">
    <router-view />

    <footer id="site-footer">
      <div class="col col--left">Snow Day Predictor &copy; 2024</div>

      <div class="col col--right">
        <ul>
          <li>
            <a
              href="https://www.iubenda.com/privacy-policy/80874445"
              class="
                iubenda-nostyle
                no-brand
                iubenda-noiframe iubenda-embed iubenda-noiframe
              "
              title="Privacy Policy "
              >Privacy Policy</a
            >
          </li>
          <li>
            <a
              href="https://www.iubenda.com/terms-and-conditions/80874445"
              class="
                iubenda-nostyle
                no-brand
                iubenda-noiframe iubenda-embed iubenda-noiframe
              "
              title="Terms and Conditions "
              >Terms and Conditions</a
            >
          </li>
        </ul>
      </div>

      <div class="col col--full">
        <p>
          Snow day school closing calculator, enter your US ZIP or Canadian
          postal code to calculate the odds of school being delayed or closed
          due to inclement weather.
        </p>
        <ul>
          <li
            v-for="([abbv, state], index) in state_list"
            :key="`statelist_${index}`"
          >
            <router-link
              :to="{
                name: 'StateDirectory',
                params: { state: abbv.toUpperCase() },
              }"
              >Snow day cities in {{ state }}</router-link
            >
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  computed: {
    leaderboard() {
      return this.$store.state.leaderboard;
    },
    state_list() {
      return Object.entries(
        Object.assign({}, this.$caProvinces, {
          MI: 'Michigan',
          OH: 'Ohio',
          IL: 'Illinois',
          IN: 'Indiana',
          WI: 'Wisconsin',
          MN: 'Minnesota',
          PA: 'Pennsylvania',
          NY: 'New York',
          NJ: 'New Jersey',
          MA: 'Massachusetts',
          ME: 'Maine',
          VT: 'Vermont',
          NH: 'New Hampshire',
          CT: 'Connecticut',
        })
      );
    },
  },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch('populateLeaderboard');
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
