import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    leaderboard: null,
    loading: false
  },
  mutations: {
    leaderboard(state, val) {
      state.leaderboard = val;
    },
    loading(state, val) {
      state.loading = val === true ? true : false;
    }
  },
  actions: {
    async populateLeaderboard({commit}) {
      const resp = await Vue.prototype.$api('GET', '/leaderboard')
      commit('leaderboard', resp);
    }
  },
  modules: {
  }
})
