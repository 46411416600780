import Vue from 'vue'
import App from './App.vue'
import numeral from 'numeral'
import router from './router'
import store from './store'

import './utils/fittext'

import '@/assets/scss/app.scss'

import SiteHeader from '@/components/Header'
Vue.component('site-header', SiteHeader)

import VueHead from 'vue-head'
Vue.use(VueHead, {
  separator: '-',
  complement: 'Snow Day Predictor'
})

import VueToast from 'vue-toast-notification';
Vue.use(VueToast)
import 'vue-toast-notification/dist/theme-sugar.css';

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: "G-RH20PCWM8T" }
});

Vue.config.productionTip = false;

Vue.prototype.$formatNumber = (number) => numeral(number).format('0,0');
Vue.prototype.$loadImage = (url) => new Promise(r => { let i = new Image(); i.onload = (() => r(i)); i.src = url; });

Vue.prototype.$usStates = JSON.parse(`{"AL":"Alabama","AK":"Alaska","AS":"American Samoa","AZ":"Arizona","AR":"Arkansas","CA":"California","CO":"Colorado","CT":"Connecticut","DE":"Delaware","DC":"District Of Columbia","FM":"Federated States Of Micronesia","FL":"Florida","GA":"Georgia","GU":"Guam","HI":"Hawaii","ID":"Idaho","IL":"Illinois","IN":"Indiana","IA":"Iowa","KS":"Kansas","KY":"Kentucky","LA":"Louisiana","ME":"Maine","MH":"Marshall Islands","MD":"Maryland","MA":"Massachusetts","MI":"Michigan","MN":"Minnesota","MS":"Mississippi","MO":"Missouri","MT":"Montana","NE":"Nebraska","NV":"Nevada","NH":"New Hampshire","NJ":"New Jersey","NM":"New Mexico","NY":"New York","NC":"North Carolina","ND":"North Dakota","MP":"Northern Mariana Islands","OH":"Ohio","OK":"Oklahoma","OR":"Oregon","PW":"Palau","PA":"Pennsylvania","PR":"Puerto Rico","RI":"Rhode Island","SC":"South Carolina","SD":"South Dakota","TN":"Tennessee","TX":"Texas","UT":"Utah","VT":"Vermont","VI":"Virgin Islands","VA":"Virginia","WA":"Washington","WV":"West Virginia","WI":"Wisconsin","WY":"Wyoming"}`);
Vue.prototype.$caProvinces = JSON.parse('{ "AB": "Alberta", "BC": "British Columbia", "MB": "Manitoba", "NB": "New Brunswick", "NL": "Newfoundland and Labrador", "NT": "Northwest Territories", "NS": "Nova Scotia", "NU": "Nunavut", "ON": "Ontario", "PE": "Prince Edward Island", "QC": "Quebec", "SK": "Saskatchewan", "YT": "Yukon Territory" }');

import Api from './api'
Vue.use(Api)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
