<template>
  <main class="page page--state-directory page--ads">
    <aside class="ad-banner ad-banner--left">
      <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-client="ca-pub-1739040075681013"
        data-ad-slot="8698597806"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </aside>

    <site-header />

    <section class="page--column-center">
      <aside class="ad-banner ad-banner--top">
        <!-- sdp-responsive-header -->
        <ins
          class="adsbygoogle"
          style="display: block"
          data-ad-client="ca-pub-1739040075681013"
          data-ad-slot="1310631173"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </aside>

      <div
        class="main-content"
        id="state-directory-content"
        v-if="state !== null"
      >
        <h1>Cities with snow days in {{ state }}</h1>
        <ul v-if="cities">
          <li v-for="(city, index) in cities" :key="`city_${index}`">
            <router-link
              :to="{ name: 'Result', params: { postalCode: city.postal } }"
              >{{ city.city }}</router-link
            >
          </li>
        </ul>
      </div>

      <aside class="ad-banner ad-banner--bottom"></aside>
    </section>
    <aside class="ad-banner ad-banner--right">
      <!-- sdp-responsive-right -->
      <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-client="ca-pub-1739040075681013"
        data-ad-slot="3185855593"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </aside>
  </main>
</template>

<script>
export default {
  data() {
    return {
      state: null,
      country: null,
      cities: null,
    };
  },
  watch: {
    $route: () => {
      this.populateState();
    },
  },
  created() {
    this.populateState();
  },
  methods: {
    async populateState() {
      const abbv = this.$route.params.state;
      const state =
        Object.keys(this.$usStates).indexOf(abbv.toUpperCase()) > -1
          ? ['US', this.$usStates[abbv.toUpperCase()]]
          : ['CA', this.$caProvinces[abbv.toUpperCase()]];

      if (!state) {
        //redirect to 404

        return;
      }

      this.state = state[1];
      this.country = state[0];

      this.cities = await this.$api(
        'GET',
        `/leaderboard/cities/${state[0]}-${abbv.toUpperCase()}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#state-directory-content {
  h1 {
    font-size: 20px;
    line-height: 1.5em;
    margin: 0 0 0;
  }

  ul {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    font-size: 14px;

    > li {
      padding: 2px 0;
      > a {
        color: #fff;
      }
    }
  }
}
</style>
