<template>
  <header id="site-header">
    <div class="content content--left">
      <router-link :to="{'name': 'Home'}">
        <img src="@/assets/logo-white.svg" alt="snow day predictor logo" height="60">
      </router-link>

      <div class="leaderboard-wrap">
        <h4>Trending Snow Day Cities</h4>
        <div id="leaderboard-ticker" class="splide" v-if="leaderboard !== null">
          <div class="splide__track">
            <ul class="splide__list">
              <li class="splide__slide" v-for="(region, index) in leaderboard" :key="`leaderboard_ticker_${index}`">
                <div>
                  <router-link :to="{name: 'Result', params: {'postalCode': region.postal}}">
                    <i v-html="leaderboardRank(region.rank)"></i>
                    {{region.name}}
                  </router-link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    
    <div class="content content--right">
      <div class="fb-like" data-href="https://facebook.com/snowdaypredictor" data-width="" data-layout="button_count" data-action="like" data-size="large" data-share="false"></div>
    </div>
  </header>
</template>

<script>
import Splide from '@splidejs/splide';

export default {
  computed: {
    leaderboard() {
      return this.$store.state.leaderboard;
    }
  },
  watch: {
    leaderboard(val, old) {
      if(old === null && val !== null) {
        this.$nextTick(this.initLeaderboardTicker)
      }
    }
  },
  data() {
    return {
      leaderboard_ticker: null
    }
  },
  mounted() {
    this.initLeaderboardTicker()
  },
  beforeDestroy() {
    this.destoryLeaderboardTicker()
  },
  methods: {
    destoryLeaderboardTicker() {
      if(this.leaderboard_ticker === null) return;

      this.leaderboard_ticker.destroy();
    },
    initLeaderboardTicker() {
      if(this.leaderboard === null) return;
      if(this.leaderboard_ticker !== null) return;

      this.leaderboard_ticker = new Splide('#leaderboard-ticker', {
        direction: 'ttb',
        height: '26px',
        //autoWidth: true,
        arrows: false,
        pagination: false,
        drag: false,
        autoplay: true,
        interval: 3000,
        pauseOnHover: true,
        type: 'loop'
      })
      this.leaderboard_ticker.mount()
    },
    leaderboardRank(rank) {
      switch(rank) {
        case 1:
          return `<span class="emoji">&#x1F947</span>`;
        case 2:
          return `<span class="emoji">&#x1F948</span>`;
        case 3:
          return `<span class="emoji">&#x1F949</span>`;
      }

      return `<span class="number">${rank}</span>`;
    }
  }
}
</script>

<style lang="scss" scoped>
.leaderboard-wrap {
  display: none;

  @include media-breakpoint-up(md) {
    padding: 0 0 0 50px;
    display: block;
  }

  > h4 {
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 5px;
  }

  #leaderboard-ticker {
    ul {
      > li {
        line-height: 26px;
        font-size: 15px;
        
        > div {
          > a {
            display: flex;
            align-content: center;
            color: #fff;
            text-decoration: none;

            i {
              font-style: normal;
              display: block;
              line-height: 26px;
              height: 26px;
              width: 30px;

              ::v-deep > .emoji {
                font-size: 22px;
              }
              ::v-deep > .number {
                background: rgba($cultured, 0.8);
                color: $rich-black;
                display: block;
                border-radius: 100%;
                height: 20px;
                width: 20px;
                text-align: center;
                font-size: 11px;
                font-weight: 600;
                line-height: 20px;
                margin: 3px 0 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>