<template>
  <main class="page page--result page--ads">
    <aside class="ad-banner ad-banner--left">
      <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-client="ca-pub-1739040075681013"
        data-ad-slot="8698597806"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </aside>

    <site-header />

    <section class="page--column-center">
      <aside class="ad-banner ad-banner--top">
        <!-- sdp-responsive-header -->
        <ins
          class="adsbygoogle"
          style="display: block"
          data-ad-client="ca-pub-1739040075681013"
          data-ad-slot="1310631173"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </aside>

      <div class="main-content" id="result-content">
        <template v-if="result !== null">
          <template v-if="'percent' in result">
            <div class="result-header">
              <h1>
                Chance of a snow day on <strong>{{ result.dayFor }}</strong> in
                <span>{{ result.city }}, {{ result.state }}</span>
              </h1>

              <div class="active-users" v-if="activeUsers > 0">
                <span class="emoji">&#128293;</span
                ><strong
                  >{{ $formatNumber(activeUsers) }} other{{
                    activeUsers === 1 ? '' : 's'
                  }}</strong
                >
                hoping for a snow day tonight.
              </div>
            </div>

            <div class="result">
              <div class="percentage" ref="percentage">
                <span ref="percentage-num">0</span><span>%</span>
                <div class="below-text">of a snow day</div>
              </div>
              <vue-svg-gauge
                :start-angle="-90"
                :end-angle="90"
                :value="result.percent"
                :min="0"
                :max="100"
                :separator-step="0"
                :inner-radius="80"
              />
            </div>
          </template>
          <template v-else-if="'weekend' in result">
            <div class="weekend-error">
              <h1>It's the weekend!</h1>
              <p>Check back on a weekday for your snow day calculation.</p>
            </div>
          </template>
          <template v-else>
            <div class="generic-error">
              <h1>Uh oh, something went wrong.</h1>
              <p>
                We ran into an issue calcuating your chance of a snow day,
                please try again.
              </p>
            </div>
          </template>
        </template>
        <template v-else> loading... </template>
      </div>

      <div class="bottom-section">
        <div class="share-bar" v-if="result && 'percent' in result">
          <h3>
            <div>
              <img
                src="@/assets/for-good-luck.svg"
                height="14"
                alt="for good luck"
              /><span class="emoji">&#128521;</span>
            </div>
          </h3>
          <div v-if="hasShareSheet">
            <button @click="shareResult" type="button">
              Share your results
            </button>
          </div>
          <div v-else>
            <div
              class="fb-share-button"
              data-href="https://snowdaypredictor.com/"
              data-layout="button_count"
              data-size="large"
            >
              <a
                target="_blank"
                href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse"
                class="fb-xfbml-parse-ignore"
                >Share</a
              >
            </div>
            <a
              href="https://twitter.com/share?ref_src=twsrc%5Etfw"
              class="twitter-share-button"
              data-size="large"
              :data-text="`There&#39;s a ${result.percent}% chance of a snow day in ${result.city}, ${result.state}!`"
              data-url="https://snowdaypredictor.com/"
              data-via="snwdaypredictor"
              data-hashtags="SnowDay"
              data-show-count="false"
              >Tweet</a
            >
          </div>
        </div>
      </div>
    </section>
    <aside class="ad-banner ad-banner--right">
      <!-- sdp-responsive-right -->
      <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-client="ca-pub-1739040075681013"
        data-ad-slot="3185855593"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </aside>
  </main>
</template>

<script>
import { VueSvgGauge } from 'vue-svg-gauge';
import Odometer from '../utils/odometer';
import { drawText } from 'multiline-canvas-text';

export default {
  name: 'Result',
  head: {
    title() {
      if (this.result !== null && 'percent' in this.result)
        return {
          inner: `Chance of a snow day in ${this.result.city}, ${this.result.state}`,
        };

      return {
        inner: `Chance of a snow day`,
      };
    },
    meta() {
      if (this.result !== null && 'percent' in this.result)
        return [
          {
            name: 'description',
            content: `Snow Day Predictor uses real-time weather data to calculate the chance of a snow day in ${this.result.city}, ${this.result.state}.`,
          },
        ];

      return [
        {
          name: 'description',
          content:
            'Snow Day Predictor uses real-time weather data to calculate the chance of a snow day your city.',
        },
      ];
    },
  },
  components: {
    VueSvgGauge,
  },
  computed: {
    activeUsers() {
      if (!this.result.region) return 0;
      return this.result.region.others_tonight;
    },
    hasShareSheet() {
      return 'share' in navigator;
    },
  },
  data() {
    return {
      result: null,
    };
  },
  created() {
    this.populateResult();
  },
  methods: {
    async shareResult() {
      const canvas = document.createElement('canvas');
      canvas.height = 1920;
      canvas.width = 1080;

      const ctx = canvas.getContext('2d');

      // Draw background
      const background = await this.$loadImage(
        require('@/assets/share-sheet/bg.png')
      );
      ctx.drawImage(background, 0, 0, 1080, 1920);

      // Draw gauge
      const gauge = await new Promise((r) => {
        const svgElement = document.querySelector('#result-content .gauge svg');
        console.log(svgElement);
        var svgURL = new XMLSerializer().serializeToString(svgElement);

        const img = new Image();
        img.onload = () => {
          r(img);
        };

        img.src =
          'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(svgURL);
      });

      ctx.drawImage(gauge, 140, 1000, 800, 400);

      // Draw percentage
      const percentageText = drawText(
        `${this.result.percent}%`,
        800,
        'sans-serif',
        160,
        170,
        '#FFFFFF',
        false,
        'center'
      );
      ctx.drawImage(percentageText.canvas, 140, 1240);

      // Draw title
      const titleText = drawText(
        `Chance of a snow day in`,
        800,
        'sans-serif',
        40,
        40,
        '#FFFFFF',
        false,
        'center'
      );
      ctx.drawImage(titleText.canvas, 140, 750);

      // Draw city
      const cityText = drawText(
        `${this.result.city}, ${this.result.state}`,
        800,
        'sans-serif',
        48,
        52,
        '#FFFFFF',
        false,
        'center'
      );
      ctx.drawImage(cityText.canvas, 140, 820);

      const blob = await new Promise((r) => canvas.toBlob(r));
      const file = new File([blob], 'SnowDayPredictor-Result.png');

      await navigator.share({
        title: `${this.result.city} has a ${this.result.percent}% chance of a snow day on ${this.result.dayFor}!`,
        files: [file],
        url: `https://snowdaypredictor.com/`,
      });
    },
    async populateResult() {
      const passedResult = this.$route.params.result;
      if (!!passedResult) {
        this.result = passedResult;
      } else {
        this.$store.commit('loading', true);
        const resp = await this.$api(
          'GET',
          `/query/${encodeURIComponent(this.$route.params.postalCode)}`
        );
        this.$store.commit('loading', false);

        this.result = resp;
      }

      this.$emit('updateHead');

      if ('percent' in this.result) {
        this.$nextTick(this.renderPercentage);
      }
    },
    renderPercentage() {
      const od = new Odometer({
        el: this.$refs['percentage-num'],
        format: 'd',
        theme: 'minimal',
      });
      window.fitText(this.$refs['percentage'], 0.66);

      od.update(this.result.percent);
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-section {
  display: flex;
  flex-direction: column;

  .share-bar {
    @include media-breakpoint-up(md) {
      order: 1;
    }
  }

  .ad-banner {
    @include media-breakpoint-up(md) {
      order: 2;
      margin: 35px 0 0;
    }
  }
}

.share-bar {
  @include media-breakpoint-up(md) {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 15px 0 0;
  }
  margin: 0 0 0;
  padding: 25px 0;
  > h3 {
    overflow: hidden;
    margin: 0;
    font-size: 20px;

    > div {
      text-align: center;

      > img {
        vertical-align: middle;
      }

      > span {
        vertical-align: middle;
        display: inline-block;
        margin: 0 0 0 10px;
      }
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0 0;

    > button {
      height: 40px;
      border: none;
      appearance: none;
      background: linear-gradient(
        350deg,
        rgba(8, 127, 187, 1) 0%,
        rgba(0, 166, 251, 1) 100%
      );
      color: #ffffff;
      padding: 0 25px;
      outline: none;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
      border-radius: 100em;
      margin: 7px 7px 0 5px;

      animation: blip 1s linear infinite;

      &:hover {
        background: linear-gradient(
          350deg,
          rgba(18, 152, 219, 1) 0%,
          rgba(35, 179, 252, 1) 100%
        );
      }

      &:active {
        background: linear-gradient(
          350deg,
          rgba(8, 127, 187, 1) 100%,
          rgba(0, 166, 251, 1) 0%
        );
      }

      &:disabled {
        background: linear-gradient(
          350deg,
          rgba(8, 127, 187, 0.5) 0%,
          rgba(0, 166, 251, 0.5) 100%
        );
      }
    }

    * {
      margin: 0 10px;
    }
  }
}

@keyframes blip {
  0% {
    box-shadow: rgba(0, 166, 251, 0) 0 0 0 0;
  }
  50% {
    box-shadow: rgba(0, 166, 251, 0.3) 0 0 0 3px;
  }
  100% {
    box-shadow: rgba(0, 166, 251, 0) 0 0 0 6px;
  }
}

.generic-error,
.weekend-error {
  > h1 {
    font-weight: 600;
    font-size: 22px;
    margin: 0;
    flex: 1;
  }
}

#result-content {
  .result-header {
    text-align: center;
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      text-align: left;
    }

    > h1 {
      font-size: 14px;
      font-weight: normal;
      margin: 0;
      flex: 1;

      > span {
        font-weight: 600;
        font-size: 22px;
        display: block;
        line-height: 1.5em;
      }
    }

    > .active-users {
      font-size: 14px;
      position: relative;
      line-height: 17px;

      @include media-breakpoint-up(md) {
        max-width: 232px;
        padding: 0 0 0 26px;
      }

      > .emoji {
        @include media-breakpoint-up(md) {
          position: absolute;
          left: 0;
        }
        font-size: 20px;
        line-height: 20px;
        top: 50%;
        margin-top: -11px;
      }
    }
  }

  .result {
    position: relative;
    font-size: 100%;
    max-width: 512px;
    margin: 35px auto 0;

    .percentage {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      bottom: 0;
      height: 48%;
      font-weight: 600;

      > span {
        display: inline-block;
      }

      .below-text {
        position: absolute;
        font-weight: normal;
        font-size: 0.25em;
        text-align: center;
        left: 0;
        right: 0;
        margin-top: -5%;
      }
    }
  }
}
</style>
